import { useTranslation } from "react-i18next"
import styles from "./AuthModal2.module.scss"
import PhoneInput from "react-phone-number-input"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useAppDispatch } from "../../../hooks"
import { useGetSmsMutation } from "../../../redux/api/auth"
import Button from "../../Assets/Button/Button"

const AuthModalLogin = ({
  setStep,
  setSeconds,
  phoneNumber,
  setPhoneNumber,
}: {
  setStep: Dispatch<SetStateAction<number>>
  setSeconds: Dispatch<SetStateAction<number>>
  phoneNumber: string
  setPhoneNumber: Dispatch<SetStateAction<string>>
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useAppDispatch()
  const inp1 = useRef<any>(null)

  const [getSms, { isLoading }] = useGetSmsMutation()

  // const [phoneNumber, setPhoneNum] = useState<string | undefined>(undefined)
  const [btnPhoneDisabled, setBtnPhoneDisabled] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<"notFound" | "blocked" | undefined>(undefined)
  // const [isErrCode, setIsErrCode] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!inp1?.current) return
    inp1.current?.focus()
  }, [])

  const getSmsByPhone = () => {
    // setLoading(true)
    // setIsErrCode(undefined)
    getSms({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, "") })
      .unwrap()
      .then((resp: any) => {
        if (resp.errorCode === "UserSmsAuthNotFound") {
          // setStep(10)
          setErrorMessage("notFound")
        } else if (resp.errorCode === "is_blocked") {
          // setStep(11)
          setErrorMessage("blocked")
        } else {
          // if (resp.errorCode === "SmsWasSentToTheThisNumber") {
          //   setSeconds(resp?.data?.ttl)
          // }
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
        // setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        // setLoading(false)
      })
  }

  const onPaste = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    const clipboardText = e.clipboardData.getData("Text").replace(/([()\-\s])/g, "")
    let replaceValue = ""

    replaceValue = clipboardText
    // if (clipboardText[0] === "+") {
    //   replaceValue = clipboardText.replace(/\+/, "")
    // } else {
    //   replaceValue = clipboardText
    // }

    // if (clipboardText.length === 12) {
    //   if (clipboardText[0] === "+") {
    //     replaceValue = clipboardText.replace(/\+7/, "")
    //   }
    // } else if (clipboardText.length === 11) {
    //   if (clipboardText[0] === "8" || clipboardText[0] === "7") {
    //     // replaceValue = clipboardText
    //     replaceValue = clipboardText.slice(1, clipboardText.length)
    //   }
    // } else if (clipboardText.length === 10) {
    //   replaceValue = clipboardText
    // }

    // setPhoneNum(`${replaceValue}`)
    setPhoneNumber(`${replaceValue}`)
    setBtnPhoneDisabled(replaceValue ? replaceValue.length < 5 : true)
    return false
  }

  const firstStepSubmit = () => {
    if (phoneNumber && !btnPhoneDisabled) {
      getSmsByPhone()
    }
    setErrorMessage(undefined)
  }

  return (
    <>
      <h2 className={styles.title}>{t("auth")}</h2>
      <p className={styles.txt}>{t("authText1")}</p>
      <div className={`modal-input`}>
        <div className={`input-label`}>{t("enterPhone")}</div>
        <PhoneInput
          ref={inp1}
          international
          defaultCountry="RU"
          value={phoneNumber}
          disabled={isLoading}
          onPaste={onPaste}
          onChange={(val) => {
            setPhoneNumber(`${val}`)
            setBtnPhoneDisabled(val ? val.length < 5 : true)
          }}
          onKeyDown={(e: any) => (e.key === "Enter" ? firstStepSubmit() : null)}
          className={styles.country}
        />
        {errorMessage && (
          <span className={`error-text`}>
            {errorMessage === "blocked" ? t("numberIsBlocked") : t("numberNotFound")}
          </span>
        )}
      </div>
      <div className={styles.authBottom}>
        {/*{type === "room" && (*/}
        {/*  <Button*/}
        {/*    mode={"gray"}*/}
        {/*    size={"sm-width"}*/}
        {/*    txt={t("back")}*/}
        {/*    onClick={() => setStep(0)}*/}
        {/*    className={styles.btnBack}*/}
        {/*  />*/}
        {/*)}*/}
        <Button
          txt={t("next")}
          icon={"arrow"}
          className={styles.btnAuth}
          onClick={firstStepSubmit}
          disabled={btnPhoneDisabled || isLoading}
          isLoading={isLoading}
        />
      </div>
    </>
  )
}

export default AuthModalLogin
