// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FavoriteBtn_btn__NlUXo {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  padding: 6px 12px;
  gap: 4px;
  border-radius: 39px;
  background: #EEEEEE;
  color: #151B26;
  font-weight: 600;
  font-size: 12px;
}
.FavoriteBtn_btn__NlUXo svg {
  stroke: var(--orange-500);
  stroke-width: 1.3px;
  color: white;
}
@media (hover: hover) {
  .FavoriteBtn_btn__NlUXo:hover svg {
    color: var(--orange-500);
  }
}
.FavoriteBtn_btn--is-active__WGKss svg {
  color: var(--orange-500);
  stroke: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/FavoriteBtn/FavoriteBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;EACA,iBAAA;EACA,QAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAAF;AAEE;EACE,yBAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAGE;EAEI;IACE,wBAAA;EAFN;AACF;AAOI;EACE,wBAAA;EACA,YAAA;AALN","sourcesContent":[".btn {\r\n  flex-shrink: 0;\r\n  \r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  transition: var(--transition);\r\n  padding: 6px 12px;\r\n  gap: 4px;\r\n  border-radius: 39px;\r\n  background: #EEEEEE;\r\n  color: #151B26;\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n\r\n  svg {\r\n    stroke: var(--orange-500);\r\n    stroke-width: 1.3px;\r\n    color: white;\r\n  }\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      svg {\r\n        color: var(--orange-500);\r\n      }\r\n    }\r\n  }\r\n\r\n  &--is-active {\r\n    svg {\r\n      color: var(--orange-500);\r\n      stroke: none;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `FavoriteBtn_btn__NlUXo`,
	"btn--is-active": `FavoriteBtn_btn--is-active__WGKss`
};
export default ___CSS_LOADER_EXPORT___;
