import Layout from "../../components/Layout/Layout"
import ArticlesAside from "../../components/Articles/ArticlesAside/ArticlesAside"
import Article from "../../components/Articles/Article/Article"
import { useNavigate, useParams } from "react-router-dom"

export default function ArticlePage() {
  const { id } = useParams()

  const navigate = useNavigate()

  if (!id) navigate("/")

  return (
    <Layout isHeaderMobHidden mainClass={"main--md"} aside={<ArticlesAside />} isUserRequired={false}>
      {id && <Article id={id} />}
    </Layout>
  )
}
