import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

const initialState: any = {
  openModal: false,
  currentLanguage: "",
}

export const languageModalSlice = createSlice({
  name: "languageModal",
  initialState,
  reducers: {
    languageModalOpen: (state: any, action: { payload: any; type: string }) => {
      action?.payload?.flag ? (state.openModal = action?.payload?.flag) : (state.openModal = !state.openModal)
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(HYDRATE as string, (state, action: PayloadAction<RootState>) => {
    //   // return action.payload[authSlice.name]
    //   if (Object.keys(action.payload[authSlice.name].users).length) {
    //     state.selectlist = action.payload[authSlice.name].selectlist
    //     state.roles = action.payload[authSlice.name].roles
    //     state.users = action.payload[authSlice.name].users
    //     state.workgroups = action.payload[authSlice.name].workgroups
    //   }
    //   if (Object.keys(action.payload[authSlice.name].user).length) {
    //     state.user = action.payload[authSlice.name].user
    //   }
    // })
    // builder.addCase(getSpeclistRoles.fulfilled, (state: AuthState, action) => {
    //   const {selectlist, roles, users, workgroups} = action.payload
    //   state.selectlist = selectlist
    //   state.roles = roles
    //   state.users = users
    //   state.workgroups = workgroups
    // })
    // builder.addCase(getUsersList.fulfilled, (state, action) => {
    //   state.users = action.payload
    // })
  },
})

export const { languageModalOpen } = languageModalSlice.actions

export const getLangModalIsOpen = (state: RootState) => state[languageModalSlice.name].openModal
