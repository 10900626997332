import { getCookie } from "../utils/cookies"
import { USER_TOKEN_COOKIE } from "../utils/constants"
import { useCheckTokenMutation } from "../redux/api/auth"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../hooks"
import { authModalOpen } from "../redux/slice/authModal"
import { languageModalOpen } from "../redux/slice/languageModal"
import { getKeyLangMap, langWithCurrentMomentCode } from "../utils/helpers"
import { useTranslation } from "react-i18next"
import { Loader } from "../components/Assets/Loader/Loader"

const WithAuth = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const token = getCookie(USER_TOKEN_COOKIE)
  const [checkToken, test] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })
  const dispatch = useAppDispatch()
  const currentLang = localStorage.getItem("i18nextLng")
  const { i18n } = useTranslation()

  const userLang = navigator.language
  const keyLang = getKeyLangMap(userLang)

  // Действия при не залогиненном пользователе
  const noAuthUser = () => {
    dispatch(authModalOpen({ flag: true }))
    if (!currentLang || currentLang === "dev" || !langWithCurrentMomentCode.get(currentLang)) {
      setTimeout(() => i18n.changeLanguage(keyLang ? keyLang[0] : "en"), 10)
    }
  }

  useEffect(() => {
    if (token) {
      checkToken(token).then(
        (result: any) => (!result?.data?.data?.user ? noAuthUser() : null), //Если есть токен, но юзер не найден
      )
    } else {
      noAuthUser()
    }
    setLoading(false)
  }, [])

  return isLoading ? <Loader /> : null
}

export default WithAuth
