import { useEffect, useState } from "react"
import { StarIcon } from "../../../icons/StarIcon"
import clsx from "clsx"
import styles from "./FavoriteBtn.module.scss"

interface Props {
  isActive: boolean
  onClick: () => void
  className?: string
}

const FavoriteBtn = ({ isActive, onClick, className }: Props) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  return (
    <button
      type={"button"}
      className={clsx(styles.btn, active && styles["btn--is-active"], className)}
      onClick={onClick}
    >
      <StarIcon /> {isActive ? "В избранном" : "Избранное"}
    </button>
  )
}

export default FavoriteBtn
